<template>
  <FormCardItem title="Seção complementar">
    <CCol lg="12">
      <FileUploader
        id="complementarySectionImage"
        :uploader-info="uploaderInfo"
        :enable-multiple="false"
        :enable-crop-on-uploader="true"
        :enable-edit-on-api-table="false"
        :max-files="1"
        accept-types="image/png,image/jpeg"
        extensions="jpg,jpeg,png"
        :medias="medias.complementarySection"
        :min-cropped-height="456"
        :min-cropped-width="550"
        :sizeFile="1024 * 1024 * 5"
        uploader-type="banner"
      />
    </CCol>

    <CCol col="12">
      <label for="complementary_section_description"> Descrição* </label>
      <TextEditor
        id="complementary_section_description"
        v-model="form.complementary_section_description"
        type="simpletext"
      />
    </CCol>
  </FormCardItem>
</template>

<script>
import FileUploader from '@/components/ui/Fileuploader'
import TextEditor from '@/components/ui/TextEditor'
import FormCardItem from '@/components/ui/Form/FormCardItem'
import formFragmentMixin from '@/mixin/form-fragment'

const uploaderInfo = {
  label: 'Imagem'
}

export default {
  mixins: [formFragmentMixin],
  components: { FileUploader, TextEditor, FormCardItem },
  props: { medias: { typer: Array, required: true } },
  data: () => ({ uploaderInfo })
}
</script>
