<template>
  <FormCardItem title="Informações">
    <CCol lg="4">
      <CInput
        id="first"
        v-model="form.title"
        label="Título*"
        placeholder="Título"
        required
        :disabled="processing"
      />
    </CCol>
    <CCol lg="4">
      <CInput
        v-model="form.subtitle"
        label="Subtítulo*"
        placeholder="Subtítulo"
        required
        :disabled="processing"
      />
    </CCol>

    <CCol lg="4" class="mb-3">
      <label for="iveco_connect">Iveco Connect</label>
      <Multiselect
        id="iveco_connect"
        :value="selectedIvecoConnect"
        :options="yesOrNotOptions"
        @select="({ value }) => (form.iveco_connect = value)"
      />
    </CCol>

    <CCol col="12">
      <label for="description"> Descrição* </label>
      <TextEditor
        id="description"
        v-model="form.description"
        type="simpletext"
      />
    </CCol>
  </FormCardItem>
</template>

<script>
import TextEditor from '@/components/ui/TextEditor'
import Multiselect from '@/components/ui/Multiselect'
import FormCardItem from '@/components/ui/Form/FormCardItem'

import formFragmentMixin from '@/mixin/form-fragment'

const yesOrNotOptions = [
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' }
]

export default {
  mixins: [formFragmentMixin],
  components: { TextEditor, Multiselect, FormCardItem },
  props: { processing: { type: Boolean, required: true } },
  data: () => ({ yesOrNotOptions }),
  computed: {
    selectedIvecoConnect() {
      const iveco_connect = this.form.iveco_connect
      return this.yesOrNotOptions.find(({ value }) => value == iveco_connect)
    }
  }
}
</script>
