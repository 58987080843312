<template>
  <CRow>
    <CCol lg="4" class="mb-2">
      <CInput
        v-model="form.benefits"
        label="Benefício*"
        placeholder="Benefício"
        :disabled="processing"
      />
    </CCol>
    <CCol lg="4" class="mb-2">
      <label :for="`differentialIcon${index}`">Ícone*</label>
      <Multiselect
        :id="`differentialIcon${index}`"
        :value="selectedIcon"
        :options="icons"
        @select="({ value }) => (form.icon_id = value)"
        class="cursor-default"
        required
      />
    </CCol>
    <CCol lg="4" class="mb-2">
      <CInput
        v-model="form.description"
        label="Descrição*"
        placeholder="Descrição"
        :disabled="processing"
      />
    </CCol>
  </CRow>
</template>

<script>
import Multiselect from '@/components/ui/Multiselect'
import formFragmentMixin from '@/mixin/form-fragment'

export default {
  mixins: [formFragmentMixin],
  components: { Multiselect },
  props: {
    processing: { type: Boolean, required: true },
    icons: { type: Array, required: true },
    index: { type: Number, required: true }
  },
  computed: {
    selectedIcon() {
      const icon = this.form.icon_id
      return this.icons.find(({ value }) => value == icon)
    }
  }
}
</script>
