<template>
  <FormCardItem title="Diferenciais">
    <template #title>
      <CCardHeader>
        <CRow class="align-items-center">
          <CCol sm="12" md="6">
            <h5 class="mb-0 d-flex align-items-center">
              <CIcon name="cil-justify-center" class="mt-md-1 mr-2" />
              Diferenciais
            </h5>
          </CCol>
          <CCol sm="12" md="6" class="card-header-actions">
            <CButton
              v-if="!!form.length"
              color="danger"
              class="float-right ml-2"
              @click="remove(null)"
            >
              <CIcon name="cil-trash" class="mr-1" />
              <span>Remover todos diferenciais</span>
            </CButton>
            <CButton
              :disabled="form.length >= 6"
              color="primary"
              class="float-right"
              @click="addItem"
            >
              <CIcon name="cil-library-add" class="mr-2" />
              Adicionar diferencial
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
    </template>
    <template>
      <CCol col="12" v-if="!!form.length">
        <DraggableComponent v-model="form">
          <template v-slot="{ index }">
            <DifferentialItem
              v-model="form[index]"
              :index="index"
              :icons="icons"
              :processing="processing"
              @remove="remove"
            />
          </template>
        </DraggableComponent>
      </CCol>
      <CCol v-if="!form.length">
        <p class="mb-0 text-muted">
          Não há diferenciais cadastrados,
          <a href="javascript:void(0)" class="text-info" @click="addItem">
            clique aqui
          </a>
          para adicionar uma novo diferencial.
        </p>
      </CCol>
    </template>
  </FormCardItem>
</template>

<script>
import formFragmentMixin from '@/mixin/form-fragment'
import IconService from '@/services/icons.service'
import DifferentialItem from './DifferentialItem.vue'
import FormCardItem from '@/components/ui/Form/FormCardItem'
import DraggableComponent from '@/components/ui/DraggableComponent'

const getDefaultDifferential = () => ({
  icon_id: '',
  benefits: '',
  description: ''
})

export default {
  components: { DifferentialItem, FormCardItem, DraggableComponent },
  mixins: [formFragmentMixin],

  props: { processing: { type: Boolean, required: true } },
  data: () => ({ icons: [] }),

  created() {
    this.getIcons()
  },

  methods: {
    async getIcons() {
      const { data } = await IconService.index({ pagination: false })
      this.icons = data.map(({ title, id }) => ({ value: id, label: title }))
    },
    remove(item) {
      this.form = this.form.filter((_, index) =>
        item === null ? false : index !== item
      )
    },
    addItem() {
      this.form = [getDefaultDifferential(), ...this.form]
    }
  }
}
</script>
