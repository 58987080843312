<template>
  <CRow>
    <Modal :modal="modal"></Modal>
    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>
      <CForm @submit.prevent="send">
        <FormCardTitle
          v-model="form"
          :title="`${isUpdate ? 'Editar' : 'Cadastrar'} Modelo`"
        >
          <template #after-status>
            <div class="ml-2 d-flex align-items-center">
              <label class="mb-0">
                <b>Destaque</b>
              </label>
              <CSwitch
                type="checkbox"
                color="warning"
                variant="3d"
                class="mx-2"
                v-bind="statusIcon"
                :checked.sync="form.favorite"
              />
            </div>
          </template>
        </FormCardTitle>

        <Informations v-model="form" :processing="processing" />

        <Images v-model="medias" />

        <Differentials
          v-model="form.differentials"
          :processing="processing"
          @update:value="(val) => (form.differentials = val)"
        />

        <ComplementarySession
          v-model="form"
          :medias="medias"
          :processing="processing"
        />

        <Seo v-model="form" :processing="processing" />

        <CRow v-if="isUpdate">
          <CCol sm="12">
            <CardLog :log="log" />
          </CCol>
        </CRow>
        <div class="d-flex justify-content-end align-items-center mt-4 mb-4">
          <a
            v-if="isUpdate"
            href="javascript:void(0)"
            class="mr-3"
            @click="resetForm"
          >
            Limpar Alterações
          </a>
          <CInputCheckbox
            v-else
            label="Criar outro"
            class="mr-3"
            :disabled="processing"
            @update:checked="setRedirect"
          />
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
// components
import CardLog from '@/components/ui/CardLog'
import Modal from '@/components/ui/Modal'
import FormCardTitle from '@/components/ui/Form/FormCardTitle'
import { joinListToSentense } from '@/utils'

// local components
import Images from './Images.vue'
import Informations from './Informations.vue'
import Differentials from './Differentials.vue'
import ComplementarySession from './ComplementarySession.vue'
import Seo from './Seo.vue'

// utils
import Service from '@/services/models.service'
import moment from 'moment'
import { cloneDeep } from 'lodash'

const defaultItem = () => ({
  banner_id: '',
  banner_mobile_id: '',
  main_image_id: '',
  complementary_section_image_id: '',
  seo_id: '',
  title: '',
  slug: '',
  seo_title: '',
  seo_description: '',
  subtitle: '',
  description: '',
  complementary_section_description: '',
  iveco_connect: 0,
  favorite: false,
  status: true,
  differentials: []
})

const defaultMedias = () => ({
  bannerDesktop: [],
  bannerMobile: [],
  mainImage: [],
  complementarySection: []
})

export default {
  metaInfo: {
    title: 'Modelos',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  components: {
    CardLog,
    ComplementarySession,
    Differentials,
    Images,
    Informations,
    Modal,
    Seo,
    FormCardTitle
  },

  data: () => ({
    item: defaultItem(),
    form: defaultItem(),
    medias: defaultMedias(),
    statusIcon: {
      labelOn: '\u2713',
      labelOff: '\u2715'
    },
    log: {},
    isLoading: true,
    processing: false,
    redirect: true,
    modal: {
      show: false,
      title: '',
      message: '',
      color: ''
    }
  }),

  async created() {
    this.setItem()
  },

  computed: {
    isUpdate() {
      return !!this.$route.params.id
    }
  },

  methods: {
    async setItem() {
      const { id } = this.$route.params
      if (id) {
        const data = await Service.show(id)

        if (data) {
          const seo = data.seo ?? {}
          this.item = {
            ...this.item,
            ...data,
            slug: seo.url ?? '',
            seo_title: seo.title ?? '',
            seo_description: seo.description ?? ''
          }

          this.medias = {
            bannerDesktop: [...data.banner],
            bannerMobile: [...data.banner_mobile],
            mainImage: [...data.main_image],
            complementarySection: [...data.complementary_section_image]
          }

          this.log = {
            created_by: data.created ? data.created.name : 'Seed',
            created_at: moment(data.created_at).format('DD/MM/YYYY HH:mm'),
            updated_by: data.updated ? data.updated.name : null,
            updated_at: moment(data.updated_at).format('DD/MM/YYYY HH:mm')
          }
        }
      }

      this.form = cloneDeep(this.item)
    },

    resetForm() {
      this.medias = defaultMedias()
      this.item = defaultItem()
      this.form = defaultItem()
      this.setItem()
    },

    setRedirect(event) {
      this.redirect = !event
    },

    async send() {
      const requiredMedias = {
        bannerDesktop: 'banner desktop',
        bannerMobile: 'banner mobile',
        mainImage: 'imagem principal',
        complementarySection: 'imagem seção complementar'
      }

      const medias = Object.keys(this.medias)
        .map((key) => !!this.medias[key].length || requiredMedias[key])
        .filter((item) => item !== true && !!item)

      if (!!medias.length) {
        const fields = joinListToSentense(medias)
        const sufix = medias.length > 1 ? 'são obrigatórios' : 'é obrigatório'

        this.modal = {
          show: true,
          title: 'Erro',
          message: `${fields} ${sufix}`,
          color: 'danger'
        }
        return
      }

      this.processing = true

      const ordering = (data) =>
        data.map((item, index) => ({ ...item, order: index + 1 }))

      let response
      let message

      const { id } = this.$route.params
      const [bannerDesktop] = this.medias.bannerDesktop
      const [bannerMobile] = this.medias.bannerMobile
      const [mainImage] = this.medias.mainImage
      const [complementarySection] = this.medias.complementarySection

      const data = {
        ...this.form,
        id,
        banner_id: bannerDesktop.id,
        banner_mobile_id: bannerMobile.id,
        main_image_id: mainImage.id,
        complementary_section_image_id: complementarySection.id,
        differentials: ordering(
          this.form.differentials.filter(({ benefits }) => !!benefits)
        )
      }

      if (this.isUpdate) {
        response = await Service.update(id, data)
        message = 'Item foi atualizada com sucesso!'
      } else {
        response = await Service.store(data)
        message = 'Item foi cadastrada com sucesso!'
      }

      this.processing = false

      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: this.redirect
        }

        if (this.redirect) {
          this.resetForm()
          setTimeout(() => {
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else {
          this.resetForm()
          setTimeout(() => {
            this.processing = false
            document.getElementById('first').focus()
            this.modal.show = false
          }, 1000)
        }
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: this.manageErrorAlert(response),
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Ocorreu um erro interno, por favor tente novamente!',
          color: 'danger'
        }
      }
    },
    manageErrorAlert(response) {
      if (!response.data.messages.length)
        return 'Preencha todos os campos corretamente!'

      const regex =
        /required_if validation failed on differentials\.(?<item>\d)\.icon_id/g

      const messages = response.data.messages.map(({ message }) => {
        if (!regex.test(message)) return message
        const item = message.replace(regex, '$<item>')
        return `ícone ${parseInt(item) + 1} de diferenciais`
      })

      return `Por favor preencha dos campos: ${joinListToSentense(messages)}`
    }
  }
}
</script>
